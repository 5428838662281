import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Router from ".";
import { NavLink, Route } from "react-router-dom";
import company1 from "../image/naver.jpg";
import company2 from "../image/sinvi.png";
import company3 from "../image/coupang.jpg";
import company4 from "../image/koreabio.png";
import logo from "../image/logo.png";

export const HomeHeader = (props) => {
  const activeStyle = {
    color: "#098D8C",
  };
  return (
    <>
      <div className={props.headerclass}>
        <NavLink to="/" activeStyle={activeStyle} className="logo">
          <img src={logo} alt="로고" />
        </NavLink>
        <ul className="nav">
          <button>
            <li>
              <NavLink to="/about" activeStyle={activeStyle}>
                회사소개
              </NavLink>
            </li>
          </button>
          <button>
            <li>
              <NavLink to="/food" activeStyle={activeStyle}>
                건강᛫일반 식품
              </NavLink>
            </li>
          </button>
          <button>
            <li>
              <NavLink to="/material" activeStyle={activeStyle}>
                특허 원료
              </NavLink>
            </li>
          </button>
          <button>
            <li>
              <NavLink to="/etc" activeStyle={activeStyle}>
                기타 원료
              </NavLink>
            </li>
          </button>
          <button>
            <li>
              <NavLink to="/qna" activeStyle={activeStyle}>
                문의하기
              </NavLink>
            </li>
          </button>
        </ul>
        <div className="sub_nav">
          <NavLink to="/admin" activeStyle={activeStyle}>
            관리자페이지
          </NavLink>
        </div>
      </div>
    </>
  );
};
export const Main = () => {
  return (
    <>
      <div className="app">
        <HomeHeader headerclass={"header"} />
        <Route exact path="/" component={Main} />
        <Route exact path="/about" component={Router.AboutUs} />
        <Route exact path="/food" component={OurProduct} />
        <Route exact path="/material" component={OurProduct} />
        <Route exact path="/etc" component={OurProduct} />
        <Route exact path="/qna" component={Router.QnA} />
        <Route exact path="/qna/detail" component={Router.QnADetail} />

      </div>
    </>
  );
};

export const Home = () => {
  return (
    <div className="app">
      <HomeHeader headerclass={"header header1"} />
      <div className="main">
        <Intro />
        <OurProduct type="" material_type="특허 원료" />
        <Member title={<h3>최상의 솔루션을 제공하는 선구자들</h3>} />
        <Recommend title={<h3>추천원료</h3>} />
        <Manufacturer title={<h3>제조사</h3>} />
      </div>
      <div className="footer">
        <img src={logo} alt="로고" />
        <div>
          <p>
            <span>(주)헬씨팜바이오</span>&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <span>대표자</span> 박준&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <span>사업자등록번호</span> 530-86-03220&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <span>TEL</span> 032-656-3040&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <span>FAX</span> 070-4038-9556&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <span>EMAIL</span> healthypharmbio@daum.net
          </p>
          <p>
            <span>본사</span> 경기도 부천시 부천로3번길 48 까뮤스퀘어피노키오 2층 226
          </p>
        </div>
      </div>
    </div>
  );
};

export const Intro = () => {
  return (
    <div className="dashboard intro">
      <h2>헬씨팜바이오</h2>
      <p>
        무료로 약사가 건강기능식품 일반식품 특허 원료 구성, 배합비
        상담해드립니다. <br />
        납기일 잘지켜준는 GMP,HACCP 제조회사 소개시켜드립니다.
      </p>
    </div>
  );
};

export const OurProduct = (props) => {
  const [tableData, setTableData] = useState([]);
  let path = useLocation();

  useEffect(() => {
    List(path.pathname);
  }, [path.pathname]);


  const List = async (pathname) => {
    try {
      let materialType;
      if (pathname == "/food") {
        materialType = "건강᛫일반 식품";
      } else if (pathname == "/material") {
        materialType = "특허 원료";
      } else if (pathname == "/etc") {
        materialType = "기타 원료";
      } else {
        materialType = "특허 원료";
      };
      await Router.CustomAxios.get("https://api.healthypharmbio.kr/b2c/items", {
        params: {
          material_type: materialType,
        },
      })
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data) {
            setTableData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div
      className={
        path.pathname == "/"
          ? "dashboard our_product"
          : "dashboard our_product details"
      }
    >
      <h3>      {path.pathname == "/food" ? "건강᛫일반 식품" : path.pathname == "/material" ? "특허 원료" :
        path.pathname == "/etc" ? "기타 원료" : "특허 원료"

      }</h3>
      <div className="img_container" key={path.pathname}>
        {tableData
          ? tableData.map((k, i) => {
            return (
              <div className="img">
                <img src={k.display_image} alt="원료" />

                <h4>{k.display_title}</h4>
                <p>{k.display_contents}</p>
              </div>
            );
          })
          : "등록된 특허 원료가 없습니다."}
      </div>
    </div>
  );
};

export const Member = (props) => {
  return (
    <>
      <div className="dashboard our_product member">
        <div className="wrap_title">
          {props.title}
          <h4>
            저희는 건강기능식품과 일반식품 분야에서 독보적인 전문성을 자랑하는
            약사들로 구성되어 있습니다.
            <br />각 분야의 선구자로서, 고객의 건강과 웰빙을 최우선으로 생각하며
            최고의 맞춤형 솔루션을 제공합니다.
          </h4>
        </div>

        <div className="img_container">
          <div>
            <h4>혁신적인 연구개발</h4>
            <p>
              우리 팀은 혁신적인 연구와 개발을 통해 건강기능식품과 일반식품의
              최적의 배합비를 설계합니다. 각 구성원은 깊이 있는 지식과 풍부한
              경험을 바탕으로, 과학적 근거에 기반한 최상의 제품을 개발하는 데
              주력하고 있습니다. 고객의 요구에 부응하는 최첨단 솔루션을 제공하기
              위해 끊임없이 연구하고 도전합니다.
            </p>
          </div>
          <div>
            <h4>고객 맞춤형 상담</h4>
            <p>
              고객의 건강 상태와 니즈를 철저히 분석하여 맞춤형 상담을 제공하는
              것이 우리의 핵심 철학입니다. 전문 약사들로 구성된 우리 팀은 각
              고객에게 개인 맞춤형 솔루션을 제공하며, 건강 목표 달성을 위한
              최적의 제품과 배합비를 제안합니다.
            </p>
          </div>
          <div>
            <h4>지속적인 교육과 발전</h4>
            <p>
              우리 팀은 지속적인 교육과 발전을 통해 최신 지식과 기술을 습득하고
              있습니다. 이를 통해 고객에게 항상 최신의 정보를 제공하며, 최첨단의
              솔루션을 제시합니다. 우리는 변화를 두려워하지 않고, 지속적으로
              성장하며 발전하는 팀입니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const Recommend = (props) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    List();
  }, []);

  const List = async () => {
    try {
      await Router.CustomAxios.get(
        "https://api.healthypharmbio.kr/b2c/recommend/items",
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data) {
            setTableData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="dashboard list">
        {props.title}
        <div className="img_container">
          {tableData
            ? tableData.map((k, i) => {
              return (
                <div className="img">
                  <img src={k.display_image} alt="장비이미지1" />

                  <h4>{k.display_title}</h4>
                  <p>{k.display_contents}</p>
                </div>
              );
            })
            : "등록된 추천 원료가 없습니다."}
        </div>
      </div>
    </>
  );
};

export const Manufacturer = (props) => {
  return (
    <>
      <div className="dashboard list Manufacturer">
        {props.title}
        <div className="img_container">
          <div className="img">
            <div className="img1">
              <img src={company1} alt="제조사" />
            </div>
          </div>
          <div className="img">
            <div className="img2">
              <img src={company2} alt="제조사" />
            </div>
          </div>
          <div className="img">
            <div className="img3">
              <img src={company3} alt="제조사" />
            </div>
          </div>
          <div className="img">
            <div className="img4">
              <img src={company4} alt="제조사" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
