import React, { useState, useEffect } from "react";
import Router from "../..";
export const ProductPopUp = (props) => {
  const [imageSrc, setImageSrc] = useState("");

  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImageSrc(reader.result);
        props.setDisplayImage(reader.result.split(",")[1]);
        props.setDisplayImageName(fileBlob.name);
        resolve();
      };
    });
  };

  return (
    <>
      {window.sessionStorage.getItem("Authorization") ? (
        <div className="modal" onClick={props.setIsPopUpClose}>
          <div className="modal_container" onClick={(e) => e.stopPropagation()}>
            <button
              className="close"
              type="button"
              onClick={props.setIsPopUpClose}
            >
              창닫기
            </button>
            <form onSubmit={props.onSubmit}>
              <p>
                이름
                <input
                  type="text"
                  value={props.displayTitle}
                  onChange={(e) => props.setDisplayTitle(e.target.value)}
                  required
                />
              </p>

              <p>
                구분
                <select
                  name="filter"
                  onChange={(e) => props.setMaterialType(e.target.value)}
                  className="select_wrap"
                  defaultValue=""
                  required
                >
                  <option disabled value="">
                    선택하기
                  </option>
                  <option value="건강᛫일반 식품">건강᛫일반 식품</option>
                  <option value="특허 원료">특허 원료</option>
                  <option value="기타 원료">기타 원료</option>
                </select>
              </p>
              <p>
                내용
                <textarea
                  type="text"
                  value={props.displayContents}
                  onChange={(e) => props.setDisplayContents(e.target.value)}
                  required
                />
              </p>
              <p>
                이미지 변경
                <input
                  type="file"
                  onChange={(e) => encodeFileToBase64(e.target.files[0])}
                  required={props.apiType == "create" ? true : false}
                />
              </p>
              <p>
                미리보기
                {imageSrc == "" ? (
                  <img src={props.displayImage} alt="원료 이미지" />
                ) : (
                  <img src={imageSrc} alt="원료 이미지" />
                )}
              </p>
              <div className="btn">
                {props.apiType == "create" ? (
                  <button type="submit" className="popup_btn">
                    등록하기
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => props.setApiType("update")}
                      className="popup_btn"
                    >
                      수정하기
                    </button>
                    <button
                      type="button"
                      className="popup_btn"
                      onClick={() => {
                        props.delete();
                      }}
                    >
                      삭제하기
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      ) : (
        window.location.replace("/")
      )}
    </>
  );
};

export const QuestionPopUp = (props) => {
  const [tableData, setTableData] = useState([]);
  const [tableDataAnswer, setTableDataAnswer] = useState([]);
  const [isAnswer, setIsAnswer] = useState(false);

  const [files, setFiles] = useState("");

  const [comment, setComment] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  const [displayTitle, setDisplayTitle] = useState("");
  const [displayPhone, setDisplayPhone] = useState("");
  const [displayContents, setDisplayContents] = useState("");

  useEffect(() => {
    List();
  }, []);

  const encodeFileToBase64 = (fileBlob) => {
    let data = [];
    for (let rows of fileBlob) {
      let json = {};
      json["file_name"] = rows.name;
      const reader = new FileReader();
      reader.readAsDataURL(rows);
      new Promise((resolve) => {
        reader.onload = () => {
          setImageSrc(reader.result.split(",")[1]);
          json["file_data"] = reader.result.split(",")[1];
          data.push(json);
          resolve();
        };
      });
    }
    setFiles(data);
  };

  const List = async () => {
    try {
      await Router.CustomAxios.get(
        "https://api.healthypharmbio.kr/admin/inquiry/detail",
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
          params: {
            board_id: props.id,
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data) {
            setDisplayTitle(res.data.board.display_title);
            setDisplayContents(res.data.board.display_contents);
            setDisplayPhone(res.data.board.contact_number);
            setTableDataAnswer(res.data.comments);
            setTableData(res.data.board);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Answer = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/reply",
        {
          board_id: props.id,
          comment: comment,
          comment_reference_file: files,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("답변이 완료되었습니다.");
            List();
            setIsAnswer(false);
            props.setIsPopUpClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/delete",
        {
          board_id: tableData.id,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("삭제가 완료되었습니다.");
            List();
            setIsAnswer(false);
            props.setIsPopUpClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/update",
        {
          id: tableData.id,
          display_title: displayTitle,
          display_contents: displayContents,
          contact_number: displayPhone,
          reference_file: tableData.reference_file,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("수정이 완료되었습니다.");
            List();
            setIsAnswer(false);
            props.setIsPopUpClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteImg = async (i) => {
    try {
      tableData.reference_file.splice(i, 1);
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/update",
        {
          id: tableData.id,
          display_title: tableData.display_title,
          display_contents: tableData.display_contents,
          contact_number: tableData.contact_number,
          reference_file: tableData.reference_file,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("이미지 삭제가 완료되었습니다.");
            List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {window.sessionStorage.getItem("Authorization") ? (
        <div className="modal" onClick={props.setIsPopUpClose}>
          <div
            className="modal_container question"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close"
              type="button"
              style={{ right: "150px" }}
              onClick={onDelete}
            >
              문의 <br /> 삭제
            </button>
            <button
              className="close"
              type="button"
              onClick={props.setIsPopUpClose}
            >
              창닫기
            </button>
            <form>
              <p>
                <span>제목 (수정가능)</span>
                <input
                  type="text"
                  value={displayTitle}
                  onChange={(e) => setDisplayTitle(e.target.value)}
                  required
                />
              </p>
              <p>
                <span style={{ width: "100%" }}>연락가능 번호 (수정가능)</span>
                <input
                  type="text"
                  value={displayPhone}
                  onChange={(e) => setDisplayPhone(e.target.value)}
                  required
                />
              </p>
              <p>
                <span>작성자</span>
                <input type="text" value={tableData.writer} readOnly required />
              </p>
              <p>
                <span>작성일</span>
                <input type="text" value={tableData.created_at} required />
              </p>

              <p>
                <span>내용 (수정가능)</span>
                <textarea
                  type="text"
                  value={displayContents}
                  onChange={(e) => setDisplayContents(e.target.value)}
                  required
                />
              </p>
              <p>
                <span>첨부파일</span>
                <span>
                  {tableData.reference_file
                    ? tableData.reference_file.map((k, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a
                              className="file"
                              href={k.file_data}
                              target="_blank"
                              download={k.file_name}
                            >
                              {k.file_name}
                            </a>
                            <button
                              type="button"
                              onClick={() => DeleteImg(i)}
                              style={{ color: "#fff" }}
                            >
                              삭제
                            </button>
                          </div>
                        );
                      })
                    : ""}
                </span>
              </p>
              <button className="popup_btn" type="button" onClick={onUpdate}>
                수정하기
              </button>
            </form>
            {tableDataAnswer.length != 0
              ? tableDataAnswer.map((tableData, i) => {
                  return <Content tableData={tableData} List={List} />;
                })
              : ""}
            {isAnswer ? (
              <form className="answer" onSubmit={Answer}>
                <p>
                  <span>내용</span>
                  <textarea
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    required
                  />
                </p>
                <p>
                  <span>첨부파일</span>
                  <span>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => encodeFileToBase64(e.target.files)}
                    />
                  </span>
                </p>
                <div className="btn">
                  <button
                    type="submit"
                    className="popup_btn"
                    style={{ marginRight: "30px" }}
                  >
                    작성
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsAnswer(false)}
                    className="popup_btn"
                  >
                    취소
                  </button>
                </div>
              </form>
            ) : (
              <button
                onClick={() => {
                  setComment("");
                  setFiles("");
                  setIsAnswer(true);
                }}
                className="popup_btn"
                type="button"
              >
                답변달기
              </button>
            )}
          </div>
        </div>
      ) : (
        window.location.replace("/")
      )}
    </>
  );
};

export const Content = (props) => {
  const [comment, setDisplayComment] = useState(props.tableData.comment);

  const DeleteReply = async (k) => {
    try {
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/comment/delete",
        {
          comment_id: k.id,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("댓글 삭제가 완료되었습니다.");
            props.List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateReply = async (k) => {
    try {
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/comment/update",
        {
          id: k.id,
          comment: comment,
          comment_reference_file: props.tableData.comment_reference_file,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("댓글 수정이 완료되었습니다.");
            props.List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const DeleteReplyImg = async (i) => {
    try {
      props.tableData.comment_reference_file.splice(i, 1);
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/inquiry/comment/update",
        {
          id: props.tableData.id,
          comment: props.tableData.comment,
          comment_reference_file: props.tableData.comment_reference_file,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("댓글 수정이 완료되었습니다.");
            props.List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form style={props.tableData.writer == "나" ? { opacity: "0.6" } : {}}>
        <p>
          <span>작성자</span>
          <input
            type="text"
            value={
              props.tableData.writer == "나" ? "관리자" : props.tableData.writer
            }
            required
          />
        </p>
        <p>
          <span>작성일</span>
          <input type="text" value={props.tableData.created_at} required />
        </p>

        <p>
          <span>내용</span>
          <textarea
            type="text"
            onChange={(e) => setDisplayComment(e.target.value)}
            value={comment}
            required
          />
        </p>
        <p>
          <span>첨부파일</span>
          <span>
            {props.tableData.comment_reference_file
              ? props.tableData.comment_reference_file.map((k, i) => {
                  return (
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                      <a
                        className="file"
                        href={k.file_data}
                        target="_blank"
                        download={k.file_name}
                      >
                        {k.file_name}
                      </a>
                      <button
                              type="button"
                              onClick={() => DeleteReplyImg(i)}
                              style={{ color: "#fff" }}
                            >
                              삭제
                            </button>
                    </div>
                  );
                })
              : ""}
          </span>
        </p>
        <div style={{ display: "flex" }}>
          <button
            className="popup_btn"
            type="button"
            style={{ marginRight: "30px" }}
            onClick={() => UpdateReply(props.tableData)}
          >
            수정하기
          </button>
          <button
            className="popup_btn"
            type="button"
            onClick={() => DeleteReply(props.tableData)}
          >
            삭제하기
          </button>
        </div>
      </form>
    </>
  );
};
