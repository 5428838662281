import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import logo from "../../image/logo.png";
import Router from "..";

export const Product = () => {
  const [tableData, setTableData] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [modalData, setModalData] = useState("");
  const [apiType, setApiType] = useState("");

  const [tabMenu, setTabMenu] = useState('all');

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedInputs, setCheckedInputs] = useState("");

  const [displayTitle, setDisplayTitle] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [displayContents, setDisplayContents] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [displayImageName, setDisplayImageName] = useState(null);
  const [remountComponent, setRemountComponent] = useState(0);
  useEffect(() => {
    List();
    setCheckedInputs("");
  }, [tabMenu]);

  const onPopUp = (k) => {
    setModalData(k);
    setDisplayTitle(k.display_title);
    setMaterialType(k.material_type);
    setDisplayContents(k.display_contents);
    setDisplayImage(k.display_image);
    setIsPopUp(true);
    setApiType("updateAndDelete")
    document.body.style.overflow = "hidden";
  };

  const onCreate = () => {
    setModalData("");
    setDisplayTitle("");
    setMaterialType("");
    setDisplayContents("");
    setIsPopUp(true); 
    setDisplayImage("")
    setApiType("create")
    document.body.style.overflow = "hidden";
  };


  const changeHandler = (checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
    }
  };

  const handleSelectAll = (checked) => {
    setIsCheckAll(!isCheckAll);
    setCheckedInputs(tableData.map((k) => k.id));
    if (isCheckAll) {
      setCheckedInputs([]);
    }
  };

  const List = async () => {
    try {
      await Router.CustomAxios.get(
        "https://api.healthypharmbio.kr/admin/item",
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
          params: {
            material_type: tabMenu,
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data) {
            setTableData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Add = async (e) => {
    e.preventDefault();
    try {
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/item/create",
        {
          display_title: displayTitle,
          display_image: displayImage,
          image_name: displayImageName,
          material_type: materialType,
          display_contents: displayContents,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            List();
            alert("등록이 완료되었습니다.");
            document.body.style.overflow = "auto";
            setIsPopUp(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Update = async (e) => {
    try {
      e.preventDefault();
      let data = {
        id: modalData.id,
        display_title: displayTitle,
        display_image: displayImage,
        material_type: materialType,
        display_contents: displayContents,
      };
      if (displayImageName != null) {
        data["image_name"] = displayImageName;
      }
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/admin/item/update",
        data,
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            List();
            alert("수정이 완료되었습니다.");
            document.body.style.overflow = "auto";
            setIsPopUp(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Delete = async (e) => {
    try {
      if (!window.confirm('정말로 삭제하시겠습니까?')) return;
      await Router.CustomAxios.post(
        'https://api.healthypharmbio.kr/admin/item/delete',
        {
          id: modalData.id,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem('Authorization'),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            List();
            alert('삭제가 완료되었습니다.');
            document.body.style.overflow = "auto";
            setIsPopUp(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Recommend = async () => {
    try {
      if (checkedInputs.length == 0) {
        alert("추천할 원료를 선택해주세요.")
        return;
      }
      await Router.CustomAxios.post(
        'https://api.healthypharmbio.kr/admin/recommend/item/add',
        {
          ids: checkedInputs,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem('Authorization'),
          },
        }
      )
        .then((res) => {
          if (res.data.type_message) {
            alert("추천은 5개까지만 가능합니다.")
            return;
          }
          if (res.data.error) {
            alert(res.data.error);
            return;
          }
          if (res.data.result) {
            List();
            setIsCheckAll(false);
            setCheckedInputs([]);
            alert('추천 등록이 완료되었습니다.');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const UnRecommend = async () => {
    try {
      if (checkedInputs.length == 0) {
        alert("추천취소할 원료를 선택해주세요.")
        return;
      }
      await Router.CustomAxios.post(
        'https://api.healthypharmbio.kr/admin/recommend/item/delete',
        {
          ids: checkedInputs,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem('Authorization'),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            List();
            setIsCheckAll(false);
            setCheckedInputs([]);
            alert('추천이 취소되었습니다.');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <>
        <div className="right_nev">
        <div className="add_item">
      <button
          onClick={() => onCreate()}
        >
          원료 등록
        </button>
        <button
          onClick={() => Recommend()}
        >
          추천하기
        </button>
        <button
          onClick={() => UnRecommend()}
        >
          추천취소
        </button>

      </div>
        <ul className="tab_menu">
          <li
            style={
              tabMenu == 'all' ? { color: '#2CF79F' } : { color: '#fff' }
            }
            onClick={() => {
              setRemountComponent(Math.random());
              setTabMenu('all');
            }}
          >모든 원료
            {tabMenu == 'all' ? <b>●</b> : ''}
            
          </li>
          <>
              <li
                style={
                  tabMenu == '건강᛫일반 식품'
                    ? { color: '#2CF79F' }
                    : { color: '#fff' }
                }
                onClick={() => {
                  setRemountComponent(Math.random());
                  setTabMenu('건강᛫일반 식품');
                }}
              >
              건강᛫일반 식품
                {tabMenu == '건강᛫일반 식품' ? <b>●</b> : ''}
              </li>
              <li
                style={
                  tabMenu == '특허 원료'
                    ? { color: '#2CF79F' }
                    : { color: '#fff' }
                }
                onClick={() => {
                  setRemountComponent(Math.random());
                  setTabMenu('특허 원료');
                }}
              >
              특허 원료
                {tabMenu == '특허 원료' ? <b>●</b> : ''}
              </li>
              <li
                style={
                  tabMenu == '기타 원료'
                    ? { color: '#2CF79F' }
                    : { color: '#fff' }
                }
                onClick={() => {
                  setRemountComponent(Math.random());
                  setTabMenu('기타 원료');
                }}
              >
                기타 원료
                {tabMenu == '기타 원료' ? <b>●</b> : ''}
              </li>

            </>
        </ul>

    </div>

      <div className="table">
        <table>
          <thead>
            <tr>
              <th className="checkbox">
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
              </th>
              <th>이름</th>
              <th>내용</th>
              <th>구분</th>
              <th>이미지</th>
              <th>추천여부</th>
              <th>생성일</th>
            </tr>
          </thead>
          <tbody>
            {tableData
              ? tableData.map((k, i) => {
                return (
                  <tr onClick={() => onPopUp(k)}>
                    <td onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        name="checkbox"
                        onChange={(e) => {
                          changeHandler(e.currentTarget.checked, k.id);
                        }}
                        checked={checkedInputs.includes(k.id) ? true : false}
                        id={k.id}
                      />
                    </td>
                    <td>{k.display_title}</td>
                    <td title={k.display_contents}>{k.display_contents}</td>
                    <td>{k.material_type}</td>
                    <td>
                      {k.display_image
                        ? k.display_image.split(",").map((k, i) => {
                          return <img src={k} alt={i} />;
                        })
                        : ""}
                    </td>
                    <td>{k.is_recommend == false ? "x" : "추천 중"}</td>
                    <td>{new Date(k.created_at).toLocaleString('sv')}</td>
                  </tr>
                );
              })
              : ""}
          </tbody>
        </table>
      </div>
      {isPopUp ? (
        <Router.ProductPopUp
          setIsPopUpClose={() => {
            setModalData("");
            document.body.style.overflow = "auto";
            setIsPopUp(false);
            List();
          }}
          displayTitle={displayTitle}
          displayContents={displayContents}
          materialType={materialType}
          displayImage={displayImage}
          setDisplayTitle={setDisplayTitle}
          setMaterialType={setMaterialType}
          setDisplayContents={setDisplayContents}
          setDisplayImage={setDisplayImage}
          setDisplayImageName={setDisplayImageName}
          onSubmit={apiType  == "create" ? Add : apiType  == "update" ? Update : ""}
          update={Update}
          delete={Delete}
          apiType={apiType}
          setApiType={setApiType}
        />
      ) : (
        ""
      )}
    </>
  );
};
