import React, { useState, useEffect } from "react";
import Router from "..";
import { Route, NavLink } from "react-router-dom";
import logo from "../../image/logo.png";
export const Admin = () => {
  return (
    <div className="wrap">
      <Route exact path="/admin" component={Login} />
      <Route path="/admin/main" component={Main} />
    </div>
  );
};

export const Main = () => {
  return (
    <>
      {window.sessionStorage.getItem('Authorization') ? (
      <div className="admin">
        <Lnb />
        <div className="main_container">
          <Route exact path="/admin/main/product" component={Router.Product} />
          <Route
            exact
            path="/admin/main/question"
            component={Router.Question}
          />
        </div>
      </div>
      ) : (
        window.location.replace('/')
      )}
    </>
  );
};

export const Lnb = () => {
  const activeStyle = {
    background: "#20232C",
    display: "flex",
    borderLeft: "5px solid #098D8C",
    alignItems: "center",
    paddingLeft: "45px",
  };

  const onClick = () => {
    window.sessionStorage.removeItem("Authorization");
    window.localStorage.removeItem("RefreshAuthorization");
    alert("로그아웃 되셨습니다.");
    window.location.replace("/");
  };

  return (
    <>
      <ul className="left_nav">
        <NavLink className="admin_header" to="/">
          <img className="header-logo" src={logo} alt="logo" />
          </NavLink>
        <li>
          <NavLink to="/admin/main/product" activeStyle={activeStyle}>
            원료 관리
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/main/question" activeStyle={activeStyle}>
            문의 관리
          </NavLink>
        </li>
        <li>
          <a onClick={onClick}>로그아웃</a>
        </li>
      </ul>
    </>
  );
};

export const Login = ({ history }) => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
          await Router.CustomAxios.post(
            'https://api.healthypharmbio.kr/b2c/signin',
            {
              private_id: userId,
              app_key: password
            }
          )
            .then((res) => {
              if (res.data.error) {
                alert(res.data.error.text);
                return;
              }
              if (res.data.type_message) {
                if(res.data.type_message == "un_match_serial_key"){
                  alert("비밀번호가 일치하지 않습니다.");
                }else{
                  alert("존재하지 않는 아이디입니다.");
                }
                return;
              }
              if (res.data.result) {
                window.sessionStorage.setItem(
                  'Authorization',
                  res.data.access_token
                );
                window.localStorage.setItem(
                  'RefreshAuthorization',
                  res.data.refresh_token
                );
                history.push(`/admin/main/product`);
              }
            })
            .catch((err) => {
              console.log(err);
            });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    {window.sessionStorage.getItem("Authorization") ? 
    history.push("/admin/main/product"):
    <div className="login">
      <div className="login_box">
        <img src={logo} alt="logo" />
        <p className="logo_font">관리자 로그인</p>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="아이디"
            required
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호"
            required
          />
          <button type="submit" className="btn">
            로그인
          </button>
        </form>
      </div>
    </div>
    }</>
  );
};
