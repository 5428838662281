import React from "react";
import "./App.css";
import { Route, withRouter } from 'react-router-dom'
import Router from './views';


const App = () =>{
  return (
    <div>
      <Route exact path="/" component={Router.Home} />
      <Route path="/about" component={Router.Main} /> 
      <Route  path="/food" component={Router.Main} />
      <Route  path="/material" component={Router.Main} />
      <Route  path="/etc" component={Router.Main} /> 
      <Route  path="/qna" component={Router.Main} /> 
      <Route path="/admin" component={Router.Admin} />
    </div>
  );
}

export default withRouter(App);