const { CustomAxios } = require('../CustomAxios');
const { Home,Main } = require('./Main');
const { AboutUs } = require('./AboutUs');
const { QnA,QnADetail } = require('./QnA');
const { Admin } = require('./admin/Admin');
const { Product } = require('./admin/Product.jsx');
const { Question } = require('./admin/Question.jsx');
const { ProductPopUp,QuestionPopUp } = require('./admin/component/PopUp');

export default {
  Home,Main,
  AboutUs,
  Admin,
  Product,
  Question,QnA,QnADetail,
  ProductPopUp,QuestionPopUp,
  CustomAxios,
};
