import axios from "axios";

export const CustomAxios = axios.create({
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});
CustomAxios.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
CustomAxios.interceptors.response.use(
  async (response) => {
    if (response.data.expired == true) {
      const rt = window.localStorage.getItem("RefreshAuthorization");
      const getToken = await axios.post(
        "https://api.healthypharmbio.kr/issuer/xt",
        {},
        {
          headers: {
            RefreshAuthorization: rt,
          },
        }
      );
      console.log(
        getToken.data,
        "response token =============================================================================="
      );
      if(getToken.data.type_message || getToken.data.error_code){
        alert("다른 기기에서 로그인하여 로그아웃되셨습니다.")
        window.sessionStorage.removeItem("Authorization");
        window.localStorage.removeItem("RefreshAuthorization");
        window.location.replace("/");
          throw "error";
      }
      window.sessionStorage.setItem(
        "Authorization",
        getToken.data.access_token
      );
      response.config.headers.Authorization = getToken.data.access_token;
      return CustomAxios(response.config);
    } else {
      return response;
    }
  },
  (error) => {
    // Promise.reject(error);
    alert("다른 기기에서 로그인하여 로그아웃되셨습니다.")
    window.sessionStorage.removeItem("Authorization");
    window.localStorage.removeItem("RefreshAuthorization");
    window.location.replace("/");
    return;
  }
);
