import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import logo from "../../image/logo.png";
import Router from "..";

export const Question = () => {
    const [tableData, setTableData] = useState([]);
    const [isPopUp, setIsPopUp] = useState(false);
    const [modalData, setModalData] = useState("");
    const [apiType, setApiType] = useState("");
  
    const [displayTitle, setDisplayTitle] = useState("");
    const [materialType, setMaterialType] = useState("");
    const [displayContents, setDisplayContents] = useState("");
    const [displayImage, setDisplayImage] = useState("");

    const [selectedPage, setSelectedPage] = useState('');
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
      List(0);
    }, []);
  
    const onPopUp = (k) => {
      setModalData(k);
      setDisplayTitle(k.display_title);
      setMaterialType(k.material_type);
      setDisplayContents(k.display_contents);
      setDisplayImage(k.display_image);
      setIsPopUp(true);
      setApiType("updateAndDelete")
      document.body.style.overflow = "hidden";
    };
  
    const List = async (page) => {
      try {
        await Router.CustomAxios.get(
          "https://api.healthypharmbio.kr/admin/inquiry",
          {
            headers: {
              Authorization: window.sessionStorage.getItem("Authorization"),
            },
            params: {
              page: page + 1,
            },
          }
        )
          .then((res) => {
            if (res.data.error) {
              console.log(res.data.error);
              return;
            }
            if (res.data) {
              setPageCount(res.data.total_page)
              setTableData(res.data.board);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };

    const handlePageClick = (e) => {
      let selectedPage = e.selected;
      setSelectedPage(selectedPage);
      loadMoreData(selectedPage);
    };

    const loadMoreData = (page) => {
      List(page);
    };

    return (
      <>
                <div className="paging">
            <ReactPaginate
              previousLabel={'이전'}
              nextLabel={'다음'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              marginPagesDisplayed={2}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
               </div>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>내용</th>
                <th>생성일</th>
              </tr>
            </thead>
            <tbody>
              {tableData
                ? tableData.map((k, i) => {
                  return (
                    <tr 
                    style={k.admin_notice == false ? {background : "#171a21", opacity : "0.5"} : {}}
                    onClick={() => onPopUp(k)}>
                      <td>{k.id}</td>
                      <td>{k.display_title}</td>
                      <td title={k.display_contents}>{k.display_contents}</td>
                      <td>{new Date(k.created_at).toLocaleString('sv')}</td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </table>
        </div>
        {isPopUp ? (
          <Router.QuestionPopUp
            setIsPopUpClose={() => {
              setModalData("");
              document.body.style.overflow = "auto";
              setIsPopUp(false);
              List(selectedPage);
            }}
            id={modalData.id}
          />
        ) : (
          ""
        )}
      </>
    );
  };
  