import React from "react";

export const AboutUs = (props) => {
  return (
    <div className="wrap_container" style={props.subMenu == "2" ? {"backgroundColor": "#121417", "height" : "100vh"}:{"backgroundColor": "#fff"} }>
      <Intro />
    </div>
  );
};

export const Intro = () => {
  return (
    <div>
        <h4>회사소개</h4>
        <p>
          갈수록 복잡해지고 다양한 원인으로 인해 건강을 회복하고자 하는 여러 원료가 필요하게 되었습니다. <br />
          이에 부흥하고조자 당사에서는 건강 회복을 방해하는 여러 원일을 해결하고자 특허 원료에 관심을 갖고 연구 개발중입니다. <br />
          첫번째 원료로 잇몸 염증, 시린이, 풍치, 영양에 도움이 되는 특허를 출원하게 되었습니다. 관심이 있는 분은 회사에 문의 주시길 바랍니다.
        </p>
        <h4>납기일을 맞춰드립니다.</h4>
        <p>우리는 고객의 소중한 시간을 존중하며, 정해진 납기일을 철저히 준수합니다. <br />
        각 단계별로 세밀한 계획을 수립하고 체계적으로 진행하여, 고객이 원하는 시기에 제품을 받을 수 있도록 최선을 다하고 있습니다.</p>
        <h4>계약서를 원하시면 제조회사와 직접 작성할 수 있게 해드립니다.</h4>
        <p>고객의 편의를 최우선으로 생각하며, 필요한 경우 제조회사와 직접 계약서를 작성할 수 있는 기회를 제공합니다. <br />
        이를 통해 고객은 더욱 투명하고 신뢰할 수 있는 계약 과정을 경험할 수 있습니다.</p>
      </div>
  );
};