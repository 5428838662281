import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Router from ".";
import { useLocation } from "react-router-dom";

export const QnA = ({ history }) => {
  const [tableData, setTableData] = useState([]);
  const [displayTitle, setDisplayTitle] = useState("");
  const [displayContents, setDisplayContents] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [files, setFiles] = useState("");
  const [isAsk, setIsAsk] = useState(false);

  const [selectedPage, setSelectedPage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  let editData;

  useEffect(() => {
    List(0);
  }, []);

  const List = async (page) => {
    try {
      await Router.CustomAxios.get(
        "https://api.healthypharmbio.kr/b2c/inquiry",
        {
          params: {
            page: page + 1,
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data) {
            setPageCount(res.data.total_page);
            setTableData(res.data.board);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const encodeFileToBase64 = (fileBlob) => {
    let data = [];
    for (let rows of fileBlob) {
      let json = {};
      json["file_name"] = rows.name;
      const reader = new FileReader();
      reader.readAsDataURL(rows);
      new Promise((resolve) => {
        reader.onload = () => {
          json["file_data"] = reader.result.split(",")[1];
          data.push(json);
          resolve();
        };
      });
    }
    setFiles(data);
  };

  const Ask = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/create",
        {
          display_title: displayTitle,
          display_contents: displayContents,
          reference_file: files,
          board_password: password,
          contact_number: phone,
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("질문이 완료되었습니다.");
            List(0);
            setIsAsk(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageClick = (e) => {
    let selectedPage = e.selected;
    setSelectedPage(selectedPage);
    loadMoreData(selectedPage);
  };

  const loadMoreData = (page) => {
    List(page);
  };

  return (
    <div className="dashboard qna">
      {isAsk ? (
        <div className="modal" onClick={() => setIsAsk(false)}>
          <div className="modal_container" onClick={(e) => e.stopPropagation()}>
            <h2>문의하기</h2>
            <form className="answer" onSubmit={Ask}>
              <p>
                <span>제목</span>
                <input
                  type="text"
                  value={displayTitle}
                  onChange={(e) => setDisplayTitle(e.target.value)}
                  required
                />
              </p>
              <p>
                <span>내용</span>
                <textarea
                  type="text"
                  value={displayContents}
                  onChange={(e) => setDisplayContents(e.target.value)}
                  required
                />
              </p>
              <p>
                <span>
                  연락가능한
                  <br />
                  전화번호
                </span>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </p>
              <p>
                <span>비밀번호</span>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </p>
              <p>
                <span>첨부파일</span>
                <span>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => encodeFileToBase64(e.target.files)}
                  />
                </span>
              </p>
              <div className="btn">
                <button
                  type="submit"
                  className="popup_btn"
                  style={{ marginRight: "30px" }}
                >
                  작성
                </button>
                <button
                  type="button"
                  onClick={() => setIsAsk(false)}
                  className="popup_btn"
                >
                  취소
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <>
          <button
            onClick={() => {
              setPassword("");
              setDisplayContents("");
              setPhone("");
              setDisplayTitle("");
              setFiles("");
              setIsAsk(true);
            }}
            className="ask_btn"
            type="button"
          >
            문의작성
          </button>
          <div className="paging">
            <ReactPaginate
              previousLabel={"이전"}
              nextLabel={"다음"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              marginPagesDisplayed={2}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>제목</th>
                  <th>확인 여부</th>
                  <th>생성일</th>
                </tr>
              </thead>
              <tbody>
                {tableData
                  ? tableData.map((k, i) => {
                      return (
                        <tr
                          onClick={() => {
                            editData =
                              window.prompt("비밀번호를 입력해주세요.");
                            history.push({
                              pathname: "/qna/detail",
                              state: {
                                id: k.id,
                                password: editData,
                              },
                            });
                          }}
                        >
                          <td>{k.id}</td>
                          <td>{k.display_title}</td>
                          <td>
                            {k.user_notice == false ? "대기 중" : "답변 완료"}
                          </td>
                          <td>{new Date(k.created_at).toLocaleString("sv")}</td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export const QnADetail = ({ history }) => {
  const [tableData, setTableData] = useState([]);
  const [tableDataAnswer, setTableDataAnswer] = useState([]);
  const [isAnswer, setIsAnswer] = useState(false);
  const [files, setFiles] = useState("");
  const [comment, setComment] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [displayTitle, setDisplayTitle] = useState("");
  const [displayPhone, setDisplayPhone] = useState("");
  const [displayContents, setDisplayContents] = useState("");

  let path = useLocation();
  useEffect(() => {
    List();
  }, []);

  const List = async () => {
    try {
      if (path.state.password == null) {
        return history.push("/qna");
      }
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/detail",
        { board_password: path.state.password },
        {
          params: {
            board_id: path.state.id,
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data) {
            if (res.data.type_message == "un_match_password") {
              alert("비밀번호가 일치하지 않습니다.");
              history.push("/qna");
            }
            setDisplayTitle(res.data.board.display_title);
            setDisplayContents(res.data.board.display_contents);
            setDisplayPhone(res.data.board.contact_number);
            setTableData(res.data.board);
            setTableDataAnswer(res.data.comments);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const encodeFileToBase64 = (fileBlob) => {
    let data = [];
    for (let rows of fileBlob) {
      let json = {};
      json["file_name"] = rows.name;
      const reader = new FileReader();
      reader.readAsDataURL(rows);
      new Promise((resolve) => {
        reader.onload = () => {
          setImageSrc(reader.result.split(",")[1]);
          json["file_data"] = reader.result.split(",")[1];
          data.push(json);
          resolve();
        };
      });
    }
    setFiles(data);
  };

  const Answer = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/comment/create",
        {
          board_id: path.state.id,
          comment: comment,
          comment_reference_file: files,
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("답변이 완료되었습니다.");
            List();
            setIsAnswer(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/update",
        {
          id: tableData.id,
          display_title: displayTitle,
          display_contents: displayContents,
          contact_number: displayPhone,
          reference_file: tableData.reference_file,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("수정이 완료되었습니다.");
            List();
            setIsAnswer(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = async (e) => {
    try {
      e.preventDefault();
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/delete",
        {
          board_id: tableData.id,
        },
        {
          headers: {
            Authorization: window.sessionStorage.getItem("Authorization"),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("삭제가 완료되었습니다.");
            List();
            setIsAnswer(false);
            history.push("/qna");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteImg = async (i) => {
    try {
      tableData.reference_file.splice(i, 1);
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/update",
        {
          id: tableData.id,
          display_title: displayTitle,
          display_contents: displayContents,
          contact_number: displayPhone,
          reference_file: tableData.reference_file,
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("이미지 삭제가 완료되었습니다.");
            List();
            setIsAnswer(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="dashboard qna_detail modal">
      <div className="modal_container question">
        <button
          onClick={() => history.push("/qna")}
          className="close"
          type="button"
          style={{ width: "70px" }}
        >
          뒤로가기
        </button>
        <button
          className="close"
          type="button"
          style={{ right: "150px" }}
          onClick={onDelete}
        >
          문의 <br /> 삭제
        </button>
        <form>
          <p>
            <span>제목 (수정가능)</span>
            <input
              type="text"
              value={displayTitle}
              onChange={(e) => setDisplayTitle(e.target.value)}
              required
            />
          </p>
          <p>
            <span style={{ width: "100%" }}>연락가능 번호 (수정가능)</span>
            <input
              type="text"
              value={displayPhone}
              onChange={(e) => setDisplayPhone(e.target.value)}
              required
            />
          </p>
          <p>
            <span>작성자</span>
            <input type="text" value={tableData.writer} required />
          </p>
          <p>
            <span>작성일</span>
            <input type="text" value={tableData.created_at} required />
          </p>

          <p>
            <span>내용 (수정가능)</span>
            <textarea
              type="text"
              value={displayContents}
              onChange={(e) => setDisplayContents(e.target.value)}
              required
            />
          </p>
          <p>
            <span>첨부파일</span>
            <span>
              {tableData.reference_file
                ? tableData.reference_file.map((k, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <a
                          className="file"
                          href={k.file_data}
                          target="_blank"
                          download={k.file_name}
                        >
                          {k.file_name}
                        </a>
                        <button type="button" onClick={() => DeleteImg(i)}>
                          삭제
                        </button>
                      </div>
                    );
                  })
                : ""}
            </span>
          </p>
          <button className="popup_btn" type="button" onClick={onUpdate}>
            수정하기
          </button>
        </form>
        {tableDataAnswer.length != 0
          ? tableDataAnswer.map((tableData, i) => {
              return <Content tableData={tableData} List={List} />;
            })
          : ""}
        {isAnswer ? (
          <form className="answer" onSubmit={Answer}>
            <p>
              <span>내용</span>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </p>
            <p>
              <span>첨부파일</span>
              <span>
                <input
                  type="file"
                  multiple
                  onChange={(e) => encodeFileToBase64(e.target.files)}
                />
              </span>
            </p>
            <div className="btn">
              <button
                type="submit"
                className="popup_btn"
                style={{ marginRight: "30px" }}
              >
                작성
              </button>
              <button
                type="button"
                onClick={() => setIsAnswer(false)}
                className="popup_btn"
              >
                취소
              </button>
            </div>
          </form>
        ) : (
          <button
            onClick={() => {
              setComment("");
              setFiles("");
              setIsAnswer(true);
            }}
            className="popup_btn"
            type="button"
          >
            답변달기
          </button>
        )}
      </div>
    </div>
  );
};

export const Content = (props) => {
  const [comment, setDisplayComment] = useState(props.tableData.comment);

  const DeleteReply = async (k) => {
    try {
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/comment/drop",
        {
          comment_id: k.id,
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("댓글 삭제가 완료되었습니다.");
            props.List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateReply = async (k) => {
    try {
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/comment/update",
        {
          id: k.id,
          comment: comment,
          comment_reference_file: props.tableData.comment_reference_file,
        }
      )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("댓글 수정이 완료되었습니다.");
            props.List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const DeleteReplyImg = async (i) => {
    try {
      props.tableData.comment_reference_file.splice(i, 1);
      await Router.CustomAxios.post(
        "https://api.healthypharmbio.kr/b2c/inquiry/comment/update",
        {
          id: props.tableData.id,
          comment: comment,
          comment_reference_file: props.tableData.comment_reference_file,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            console.log(res.data.error);
            return;
          }
          if (res.data.result) {
            alert("이미지 삭제가 완료되었습니다.");
            props.List();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form style={props.tableData.writer == "나" ? { opacity: "0.6" } : {}}>
        <p>
          <span>작성자</span>
          <input type="text" value={props.tableData.writer} required />
        </p>
        <p>
          <span>작성일</span>
          <input type="text" value={props.tableData.created_at} required />
        </p>

        <p>
          <span>내용</span>
          {props.tableData.writer == "헬씨팜바이오" ? (
            <textarea type="text" value={props.tableData.comment} required />
          ) : (
            <textarea
              type="text"
              onChange={(e) => setDisplayComment(e.target.value)}
              value={comment}
              required
            />
          )}
        </p>
        <p>
          <span>첨부파일</span>
          <span>
            {props.tableData.comment_reference_file
              ? props.tableData.comment_reference_file.map((k, i) => {
                  return (
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                      <a
                        className="file"
                        href={k.file_data}
                        target="_blank"
                        download={k.file_name}
                      >
                        {k.file_name}
                      </a>
                      {props.tableData.writer == "헬씨팜바이오" ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          onClick={() => DeleteReplyImg(i)}
                        >
                          삭제
                        </button>
                      )}
                    </div>
                  );
                })
              : ""}
          </span>
        </p>
        {props.tableData.writer == "헬씨팜바이오" ? (
          ""
        ) : (
          <div style={{ display: "flex" }}>
            <button
              className="popup_btn"
              type="button"
              style={{ marginRight: "30px" }}
              onClick={() => UpdateReply(props.tableData)}
            >
              수정하기
            </button>
            <button
              className="popup_btn"
              type="button"
              onClick={() => DeleteReply(props.tableData)}
            >
              삭제하기
            </button>
          </div>
        )}
      </form>
    </>
  );
};
